import React from "react";
import { Container, Row } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import CatalogoPromos from "../../components/layouts/CatalogoPromos";

import Layout from "../../components/layouts/layout";
import SEO from "../../components/seo";

export default function Promociones(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem={"promo-taller"} parameters={parameters}>
            <SEO
                title="Nissan Rivero - Promociones Nissan - Autos meses sin intereses"
                description="Nissan Rivero - Promociones Nissan - Autos meses sin intereses - Promociones autos - Taller Nissan"
                keywords="Servicio Nissan"
                path="/promociones/taller-de-servicio"
            />
            <Container>
                <Row className="pb-2">
                    <img
                        src="https://d3s2hob8w3xwk8.cloudfront.net/banners/banner-promociones-taller-chevrolet-rivero.jpg"
                        style={{ width: "100%" }}
                        alt="banner accesorios"
                    />
                </Row>

                <CatalogoPromos promoType="taller" props={props}/>

                <Row className="pt-3 pb-3">
                    <h2 style={{ fontSize: 12 }}>
                        Nuestras promociones Nissan te ayudan a mantener tu auto en las mejores condiciones
                    </h2>
                </Row>
            </Container>
        </Layout>
    )

}